<script setup lang="ts">
import { initDarkmode } from '/@src/stores/darkmode'
import { useUserSession } from '/@src/stores/userSession'

useHead(() => ({
  title: 'LUNA Enterprise',
}))

const userSession = useUserSession()

/**
 * Initialize the darkmode watcher
 *
 * @see /@src/stores/darkmode
 */
initDarkmode()
</script>

<template>
  <div>
    <Suspense>
      <RouterView v-slot="{ Component }">
        <Transition name="fade-slow" mode="out-in">
          <div v-if="!userSession.loading">
            <component
              :is="Component"
            />
          </div>
          <section
            v-else
            class="hero is-fullheight"
            style="background-color: #451c7a"
          >
            <div class="hero-body">
              <div class="container has-text-centered">
                <p class="title has-text-white">
                  LUNA Enterprise
                </p>
                <p class="subtitle is-6 has-text-white">
                  Loading...
                </p>
                <VLoader
                  size="large"
                  class="has-text-white"
                  :active="true"
                  style="height: 100px;"
                />
              </div>
            </div>
          </section>
        </Transition>
      </RouterView>
    </Suspense>
    <ClientOnly>
      <VReloadPrompt app-name="Enterprise" />
    </ClientOnly>
  </div>
</template>

<style  lang="scss">
.has-loader .v-loader-wrapper {
  background: transparent !important;
}
</style>
