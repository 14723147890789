import { _HasDataLoaderMeta } from 'unplugin-vue-router/runtime'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/working_dir/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/working_dir/src/pages/[...all].vue'),
    /* no children */
  },
  {
    path: '/auth',
    name: '/auth',
    component: () => import('/working_dir/src/pages/auth.vue'),
    children: [
      {
        path: 'login',
        name: '/auth/login',
        component: () => import('/working_dir/src/pages/auth/login.vue'),
        /* no children */
      },
      {
        path: 'signup',
        name: '/auth/signup',
        component: () => import('/working_dir/src/pages/auth/signup.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/docs',
    /* internal name: '/docs' */
    /* no component */
    children: [
      {
        path: 'home',
        name: '/docs/home',
        component: () => import('/working_dir/src/pages/docs/home.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/explorer',
    name: '/explorer',
    component: () => import('/working_dir/src/pages/explorer.vue'),
    children: [
      {
        path: '',
        name: '/explorer/',
        component: () => import('/working_dir/src/pages/explorer/index.vue'),
        /* no children */
      },
      {
        path: 'worklist',
        /* internal name: '/explorer/worklist' */
        /* no component */
        children: [
          {
            path: '',
            name: '/explorer/worklist/',
            component: () => import('/working_dir/src/pages/explorer/worklist/index.vue'),
            /* no children */
          }
        ],
      }
    ],
    meta: {
      "requiresAuth": false,
      "requiresAPI": true
    }
  },
  {
    path: '/operations',
    name: '/operations',
    component: () => import('/working_dir/src/pages/operations.vue'),
    children: [
      {
        path: '',
        name: '/operations/',
        component: () => import('/working_dir/src/pages/operations/index.vue'),
        /* no children */
      }
    ],
    meta: {
      "requiresAuth": true,
      "requiresAPI": true
    }
  },
  {
    path: '/pathfinder',
    name: '/pathfinder',
    component: () => import('/working_dir/src/pages/pathfinder.vue'),
    children: [
      {
        path: '',
        name: '/pathfinder/',
        component: () => import('/working_dir/src/pages/pathfinder/index.vue'),
        /* no children */
      },
      {
        path: 'analytics',
        name: '/pathfinder/analytics',
        component: () => import('/working_dir/src/pages/pathfinder/analytics.vue'),
        /* no children */
      },
      {
        path: 'worklist',
        /* internal name: '/pathfinder/worklist' */
        /* no component */
        children: [
          {
            path: '',
            name: '/pathfinder/worklist/',
            component: () => import('/working_dir/src/pages/pathfinder/worklist/index.vue'),
            /* no children */
          }
        ],
      }
    ],
    meta: {
      "requiresAuth": false,
      "requiresAPI": true
    }
  },
  {
    path: '/rova',
    name: '/rova',
    component: () => import('/working_dir/src/pages/rova.vue'),
    children: [
      {
        path: '',
        name: '/rova/',
        component: () => import('/working_dir/src/pages/rova/index.vue'),
        /* no children */
      },
      {
        path: 'booster',
        name: '/rova/booster',
        component: () => import('/working_dir/src/pages/rova/booster.vue'),
        /* no children */
      }
    ],
    meta: {
      "requiresAuth": true,
      "requiresAPI": false
    }
  },
  {
    path: '/settings',
    name: '/settings',
    component: () => import('/working_dir/src/pages/settings.vue'),
    children: [
      {
        path: 'profile',
        name: '/settings/profile',
        component: () => import('/working_dir/src/pages/settings/profile.vue'),
        children: [
          {
            path: '',
            name: '/settings/profile/',
            component: () => import('/working_dir/src/pages/settings/profile/index.vue'),
            /* no children */
          },
          {
            path: 'admin',
            name: '/settings/profile/admin',
            component: () => import('/working_dir/src/pages/settings/profile/admin.vue'),
            children: [
              {
                path: 'connection-manager',
                name: '/settings/profile/admin/connection-manager',
                component: () => import('/working_dir/src/pages/settings/profile/admin/connection-manager.vue'),
                /* no children */
              },
              {
                path: 'pivot-bands',
                name: '/settings/profile/admin/pivot-bands',
                component: () => import('/working_dir/src/pages/settings/profile/admin/pivot-bands.vue'),
                /* no children */
              },
              {
                path: 'shared-worklists',
                name: '/settings/profile/admin/shared-worklists',
                component: () => import('/working_dir/src/pages/settings/profile/admin/shared-worklists.vue'),
                /* no children */
              },
              {
                path: 'status',
                name: '/settings/profile/admin/status',
                component: () => import('/working_dir/src/pages/settings/profile/admin/status.vue'),
                /* no children */
              },
              {
                path: 'users',
                name: '/settings/profile/admin/users',
                component: () => import('/working_dir/src/pages/settings/profile/admin/users.vue'),
                /* no children */
              }
            ],
            meta: {
              "requiresAdmin": true
            }
          },
          {
            path: 'settings',
            name: '/settings/profile/settings',
            component: () => import('/working_dir/src/pages/settings/profile/settings.vue'),
            /* no children */
          },
          {
            path: 'validations',
            name: '/settings/profile/validations',
            component: () => import('/working_dir/src/pages/settings/profile/validations.vue'),
            /* no children */
          }
        ],
      }
    ],
    meta: {
      "requiresAuth": true,
      "requiresAPI": true
    }
  },
  {
    path: '/test',
    name: '/test',
    component: () => import('/working_dir/src/pages/test.vue'),
    /* no children */
  },
  {
    path: '/windows',
    /* internal name: '/windows' */
    /* no component */
    children: [
      {
        path: 'form',
        name: '/windows/form',
        component: () => import('/working_dir/src/pages/windows/form.vue'),
        /* no children */
      },
      {
        path: 'surveyor',
        name: '/windows/surveyor',
        component: () => import('/working_dir/src/pages/windows/surveyor.vue'),
        /* no children */
      }
    ],
  }
]
